<template>
	<div>
		<div class="col-12 col-xl-5">
			<div class="card">
				<div class="card-header justify-content-between">
					<h4 class="card-header-title">Marshal Information</h4>
					<div class="dropdown"></div>
				</div>
				<div class="card-body p-2">
					<div class="list-group list-group-flush mb-4">
						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Name</span>
								</div>
								<div class="col-auto">
									<span class="">{{
										`${marshal.fname} ${marshal.lname}`
									}}</span>
								</div>
							</div>
						</div>
						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Phone Number</span>
								</div>
								<div class="col-auto">
									<span class="">{{ marshal.phone }}</span>
								</div>
							</div>
						</div>
						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Email Address</span>
								</div>
								<div class="col-auto">
									<span class="">{{ marshal.email }}</span>
								</div>
							</div>
						</div>
						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Gender</span>
								</div>
								<div class="col-auto">
									<span class="" v-if="marshal.gender">{{
										marshal.gender | titleCase
									}}</span>
								</div>
							</div>
						</div>
						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Date Joined</span>
								</div>
								<div class="col-auto">
									{{ marshal.created_at | date('MMM do, yyyy') }}
								</div>
							</div>
						</div>
						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Route Assigned</span>

								</div>
							</div>
						</div>

						<div class="list-group-item">
							<div class="row align-items-center">
								<div class="col">
									<span class="text-muted text-uppercase">Ratings</span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="container-fluid">
			<div class="row">
				<div class="col-12">
					<div class="card">
						<div class="card-header justify-content-between">
							<div class="input-group input-group-flush d-flex flex-row-reverse">
								<input v-model.trim="search" class="form-control list-search" type="search" placeholder="Search" />
								<span class="input-group-text border-0">
									<i class="fe fe-search"></i>
								</span>
							</div>
							<div class="d-flex justify-content-between align-items-center">

								<div class="">
									<download-button v-if="marshal.length" :downloading="downloadingReport" label="Download Report"
										@download="downloadReport()"></download-button>
								</div>
							</div>
						</div>

						<b-table striped hover selectable responsive :items="marshal" :fields="fields" :current-page="currentPage"
							:busy="fetchingMarshal" @row-clicked="viewMarshal">
							<template #table-busy>
								<div class="text-center text-secondary my-2">
									<strong>Loading...</strong>
								</div>
							</template>

							<template #head(checkAll)>
								<input v-model="checkAllMarshal" type="checkbox" id="check-all" />
							</template>
							<template #cell(checkAll)="data">
								<input :checked="selectedMarshal.find(
									(marshal) => marshal.id === data.item.id
								) || checkAllMarshal
								" @input="selectMarshal(data.item)" type="checkbox" id="check-all" />
							</template>
							<template #cell(Marshal)="data">
								<p class="font-weight-bold" style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ `${data.item.fname} ${data.item.lname}` }}</router-link>
								</p>
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ data.item.email }}</router-link>
								</p>
								<p style="line-height: 1">
									<router-link class="sh-page-link" :to="{
										name: 'ShowMarshal',
										params: { marshalId: data.item.id }
									}">{{ data.item.phone }}</router-link>
								</p>
							</template>
							<template #cell(average_rating)="data">
								<average-rating :rating="data.item.average || 0" :total-trips="data.item.trip_count"></average-rating>
							</template>
							<template #cell(avatar)="data">
								<user-avatar :user="data.item" size="sm"></user-avatar>
							</template>

							<template #cell(created_at)="data">
								{{ data.value | date('dd/MM/yyyy') }}
							</template>

							<template #cell(status)="data">
								<span class="badge text-white" :class="data.item.active == 1 ? 'bg-success' : 'bg-danger'">{{
									data.item.active == 1 ? 'Active' : 'Inactive' }}</span>
							</template>
						</b-table>

						<div class="card-footer" v-if="totalRecords">
							<b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import Swal from 'sweetalert2'
import { ExportToCsv } from 'export-to-csv'
import { joinWithRatingsData } from '@/utils/helpers'
import moment from 'moment'
import DownloadButton from '@/components/core/DownloadButton.vue'
import _ from 'lodash'

export default {
  components: {
    DownloadButton
  },
  props: {
    marshalId: {
      required: true,
      type: [Number, String]
    }
  },
  data() {
    return {
      fields: [
        {
          key: 'checkAll',
          label: 'a'
        },
        {
          key: 'route',
          label: 'Route'
        },
        {
          key: 'itinerary',
          label: 'Itinerary'
        },

        {
          key: 'completed_cico',
          label: 'Completed CICO'
        },
        {
          key: 'no_shows',
          label: 'No shows'
        }
      ],
      marshalRoutes: [
        {
          route_code: 'IKR104',
          itenerary: '10:00 AM',
          pickup: 'Sandfill Bus stop, Ozumba Mbadiwe, Victoria Island, Lagos State',
          destination: 'Shuttlers, Jasper Ike, Maroko, Lagos State'
        }
      ],
      marshal: '',
      processingExecution: false,
      loading: true,
      errorLoading: false,
      currentCreditSystem: null,
      needCreditSystem: false,
      error: null,
      trips: [],
      vehicles: [],
      perPage: 20,
      currentPage: 1,
      totalRecords: 0,
      marshals: [],
      fetchingMarshal: true,
      search: '',
      checkAllMarshal: false,
      selectedMarshal: [],
      processing: false,

      downloadingReport: false,
      settingsId: '',
      filter: {
        range: []
      }
    }
  },
  created() {
    this.init()
    this.initMarshal()
    this.debouncedCallback = _.debounce(() => {
      this.initMarshal(true)
    }, 1000)
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser
    },
    isMarshalActive() {
      if (!this.marshal) {
        return false
      }

      return !!parseInt(this.marshal.active)
    },
    isMarshalBlocked() {
      return !!this.marshal.is_blocked
    },
    marshalDevice() {
      return this.marshal &&
        Object.prototype.hasOwnProperty.call(this.marshal, 'device')
        ? this.marshal.device?.device_brand
        : 'Not Available'
    },
    filteredMarshal() {
      const search = this.search.toLowerCase()

      const marshals = this.marshals.filter((marshal) => {
        return (
          marshal.fname.toLowerCase().includes(search) ||
          marshal.lname.toLowerCase().includes(search) ||
          marshal.email.toLowerCase().includes(search) ||
          marshal.phone.toLowerCase().includes(search)
        )
      })

      return marshals
    },

    dateRange() {
      return this.filter.range.map((date) => moment(date).format('YYYY-MM-DD'))
    },

    dateFilterValid() {
      return (
        this.filter.range.length &&
        this.filter.range.filter((date) => !!date).length > 0
      )
    }
  },
  watch: {
    checkAllMarshal(value) {
      if (value) {
        this.selectedMarshal = [...this.marshal]
      } else {
        this.selectedMarshal = []
      }
    },
    currentPage() {
      this.initMarshal()
    },
    search() {
      this.debouncedCallback()
    },
    statusFilter() {
      this.initMarshal(true)
    },
    dateFilterValid() {
      this.initMarshal(true)
    }
  },
  methods: {
    async init() {
      this.loading = true
      this.errorLoading = false

      this.fetchMarshal()
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchMarshal() {
      return this.axios
        .get(`/v1/bus-marshals/${this.marshalId}`)
        .then((res) => {
          this.marshal = res.data
          
        })
    },
    //
    fetchVehicles() {
      return this.axios.get('/v1/vehicles').then((res) => {
        this.vehicles = res.data
      })
    },
    //
    async unblockMarshal() {
      const isBlockedAction = this.isMarshalBlocked
      await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: `Are you sure you want to ${this.isMarshalBlocked ? 'unblock' : 'block'
          } this user?`,
        showConfirmButton: true,
        showCancelButton: true,
        preConfirm: () => {
          return this.axios
            .patch(`/v1/bus-marshals/${this.marshalId}/blocked`, {
              isBlocked: !this.isMarshalBlocked
            })
            .then((res) => {
              this.user = res.data
              return res.data
            })
            .catch((error) => {
              const msg =
                error.response && error.response.data
                  ? error.response.data.message
                  : 'An error occured, please try again.'
              this.$swal().showValidationMessage(msg)
            })
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          this.$swal({
            icon: 'success',
            title: 'Success',
            text: `user has been ${isBlockedAction ? 'unblocked' : 'blocked'
              } successfully`,
            showCloseButton: true
          })
        }
      })
    },
    processRouteAdded() {
      this.fetchMarshalTrips()
    },
    processVehicleAssigned(vehicleId) {
      this.marshal.vehicle_id = vehicleId
    },
    processVehicleUpdated(vehicle) {
      this.marshal.vehicle = { ...vehicle }
    },
    async fetchTripRatingSettings(serviceId) {
      const tripRatingSettingsResponse = await this.axios.get(
        `/rating/settings/service-id/${serviceId}`
      )
      return tripRatingSettingsResponse.data.data
    },
    async initMarshal(reset = false) {
      if (reset) {
        this.currentPage = 1
      }

      this.fetchingMarshal = true

      const { reference } = await this.fetchTripRatingSettings(
        process.env.VUE_APP_TRIP_RATING_SERVICE_ID
      )
      this.settingsId = reference

      const ratingsPayload = {
        settings_id: this.settingsId,
        parameter: 'marshal_id',
        start_date: '2022-01-01',
        end_date: `${moment(new Date()).format('YYYY-MM-DD')}`
      }

      Promise.all([
        Array.isArray(this.dateRange) && !this.dateRange.length
          ? this.axios.get(`/v1/bus-marshals?page=${this.currentPage}&limit=${this.perPage}&search=${this.search}&metadata=true&status=${this.statusFilter}`)
          : this.axios.get(`/v1/bus-marshals?metadata=true&page==${this.currentPage}&limit=${this.perPage}&start_date_filter=${this.dateRange[0]}&end_date_filter=${this.dateRange[1]}`),
        this.axios.post('/rating/reports/generic', ratingsPayload)
      ])
        .then((res) => {
          const marshal = res[0].data.data
          const marshalRatings = res[1].data.data
          const mergedData = joinWithRatingsData(marshal, marshalRatings)
          this.marshal = mergedData
          this.totalRecords = res[0].data?.metadata?.total
        })
        .catch(() => { })
        .finally(() => (this.fetchingMarshal = false))
    },
    selectMarshal(selectedMarshal) {
      const index = this.selectedMarshal.findIndex(
        (marshal) => marshal.id === selectedMarshal.id
      )

      if (index !== -1) {
        this.selectedMarshal.splice(index, 1)
      } else {
        this.selectedMarshal.push(selectedMarshal)
      }
    },
    async deactivateSelectedMarshal() {
      const result = await this.$swal({
        icon: 'question',
        title: 'Please Confirm',
        text: 'Are you sure you want to deactivate selected Marshal(s)?',
        showConfirmButton: true,
        showCancelButton: true
      })
      if (result.isConfirmed) {
        this.processing = true

        const payload = {
          ids: this.selectedMarshal.map((marshal) => marshal.id),
          active: 0
        }

        this.axios
          .patch('/v1/bus-marshals/update-availability', payload)
          .then(() => {
            this.$swal({
              icon: 'success',
              title: 'marshal deactivated',
              text: 'All selected marshal were successfully deactivated',
              showCloseButton: true
            })
          })
          .finally(() => {
            this.reset()
            this.initMarshal()
          })
      }
    },
    reset() {
      this.processing = false
      this.selectedMarshal = []
      this.checkAllMarshal = false
    },
    viewMarshal(marshal) {
      this.$router.push({ name: 'ShowMarshal', params: { marshalId: marshal.id } })
    },
    downloadReport() {
      this.downloadingReport = true
      const csvData = this.filteredMarshal.map((data) => {
        return {
          name: data.fname + ' ' + data.lname,
          average_rating: data.average_rating,
          date_joined: data.created_at,
          status: data.active == 1 ? 'Active' : 'Inactive'
        }
      })

      const csvParams = {
        fieldSeparator: ',',
        filename: !this.dateRange.length ? 'Marshal List' : `MArshal List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        showTitle: true,
        title: !this.dateRange.length ? 'Marshal List' : `Marshal List from ${this.dateRange[0]}  to ${this.dateRange[1]}`,
        useTextFile: false,
        useBom: true,
        headers: [
          'Name',
          'Average Rating',
          'Date Joined',
          'Status'
        ]
      }

      const csvExporter = new ExportToCsv(csvParams)

      csvExporter.generateCsv(csvData)
      this.downloadingReport = false
      this.$swal({
        icon: 'success',
        title: 'Report downloaded',
        text: 'Report has been downloaded successfully',
        showCloseButton: true
      })
    }
  },
  beforeDestroy() {
    this.debouncedCallback.cancel()
  }
}
</script>

<style lang="scss" scoped>
@use "src/assets/scss/b-table";

.route-code {
  background: #D3DCE6 !important;
  color: black;
  font-size: 12px;

}

.potty {
  padding-left: 35px;
}
</style>
